<template>
    <div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
        <div class="container">
            <div class="az-content-left az-content-left-components">
                <div class="component-item">
                    <label>Asset Registry</label>
                    <nav class="nav flex-column">
                        <router-link :to="{ name: 'assets-home'}" class="nav-link" exact>Home</router-link>
                        <router-link :to="{ name: 'assets-catalogs'}" class="nav-link" exact>Catalogs</router-link>
                        <router-link :to="{ name: 'assets'}" class="nav-link" exact v-if="$route.name === 'assets'">Assets</router-link>
                        <span class="nav-link cursor-not-allowed" v-else>Assets</span>
                    </nav>
                </div>
            </div>
            <div class="az-content-body pd-lg-l-40 d-flex flex-column" style="min-height: 600px;">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AssetRegistryLayout"
    }
</script>

<style scoped>

</style>